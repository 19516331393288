import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 mb-3" }
const _hoisted_3 = { class: "card mb-5 mb-xl-10" }
const _hoisted_4 = { class: "card-header border-0 cursor-pointer" }
const _hoisted_5 = { class: "card-title m-0" }
const _hoisted_6 = {
  key: 0,
  class: "fw-bolder m-0"
}
const _hoisted_7 = {
  key: 1,
  class: "fw-bolder m-0"
}
const _hoisted_8 = { class: "card-body border-top p-9" }
const _hoisted_9 = { class: "row mb-6" }
const _hoisted_10 = { class: "col-lg-4 col-form-label required fw-bold fs-6" }
const _hoisted_11 = { class: "col-lg-8" }
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { class: "col-lg-12 fv-row" }
const _hoisted_14 = ["placeholder"]
const _hoisted_15 = { class: "row mb-6" }
const _hoisted_16 = { class: "col-lg-4 col-form-label required fw-bold fs-6" }
const _hoisted_17 = { class: "col-lg-8" }
const _hoisted_18 = { class: "row" }
const _hoisted_19 = { class: "col-lg-12 fv-row" }
const _hoisted_20 = {
  selected: "",
  value: "",
  disabled: ""
}
const _hoisted_21 = ["value"]
const _hoisted_22 = { class: "row mb-6" }
const _hoisted_23 = { class: "col-lg-4 col-form-label fw-bold fs-6" }
const _hoisted_24 = { class: "col-lg-8" }
const _hoisted_25 = { class: "row" }
const _hoisted_26 = { class: "col-lg-12 fv-row" }
const _hoisted_27 = ["placeholder"]
const _hoisted_28 = { class: "row mb-6" }
const _hoisted_29 = { class: "col-lg-4 col-form-label required fw-bold fs-6" }
const _hoisted_30 = { class: "col-lg-8" }
const _hoisted_31 = { class: "row" }
const _hoisted_32 = { class: "col-lg-12 fv-row" }
const _hoisted_33 = { class: "row mb-6" }
const _hoisted_34 = { class: "col-lg-4 col-form-label required fw-bold fs-6" }
const _hoisted_35 = { class: "col-lg-8" }
const _hoisted_36 = { class: "row" }
const _hoisted_37 = { class: "col-lg-12 fv-row" }
const _hoisted_38 = {
  selected: "",
  disabled: "",
  value: ""
}
const _hoisted_39 = { value: "1" }
const _hoisted_40 = { value: "5" }
const _hoisted_41 = { class: "row mb-6" }
const _hoisted_42 = { class: "col-lg-4 col-form-label required fw-bold fs-6" }
const _hoisted_43 = { class: "col-lg-8" }
const _hoisted_44 = { class: "row" }
const _hoisted_45 = { class: "col-lg-12 fv-row" }
const _hoisted_46 = { value: "TODO" }
const _hoisted_47 = { value: "INPR" }
const _hoisted_48 = { value: "DONE" }
const _hoisted_49 = { class: "row mb-6" }
const _hoisted_50 = { class: "col-lg-4 col-form-label fw-bold fs-6" }
const _hoisted_51 = { class: "col-lg-8" }
const _hoisted_52 = { class: "row" }
const _hoisted_53 = { class: "col-lg-12 fv-row" }
const _hoisted_54 = ["placeholder"]
const _hoisted_55 = { class: "card-footer d-flex justify-content-end py-6 px-9" }
const _hoisted_56 = { class: "indicator-label" }
const _hoisted_57 = { class: "indicator-progress" }
const _hoisted_58 = { class: "indicator-validate" }
const _hoisted_59 = { class: "indicator-label" }
const _hoisted_60 = { class: "indicator-progress" }
const _hoisted_61 = { class: "indicator-validate" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (!_ctx.editing)
              ? (_openBlock(), _createElementBlock("h3", _hoisted_6, _toDisplayString(_ctx.$t("pages.roadmap.addRoadmap")), 1))
              : (_openBlock(), _createElementBlock("h3", _hoisted_7, _toDisplayString(_ctx.$t("pages.roadmap.updateRoadmap")), 1))
          ])
        ]),
        _createVNode(_component_Form, {
          id: "kt_add_action_form",
          class: "form",
          novalidate: "novalidate"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t("pages.roadmap.yourAction")), 1),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, [
                      _withDirectives(_createElementVNode("textarea", {
                        class: "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                        placeholder: _ctx.$t('pages.roadmap.yourAction'),
                        name: "action",
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.action.label) = $event))
                      }, null, 8, _hoisted_14), [
                        [_vModelText, this.action.label]
                      ])
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.$t("pages.roadmap.themes")), 1),
                _createElementVNode("div", _hoisted_17, [
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("div", _hoisted_19, [
                      _withDirectives(_createElementVNode("select", {
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((this.action.category) = $event)),
                        class: "form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                      }, [
                        _createElementVNode("option", _hoisted_20, _toDisplayString(_ctx.$t("pages.driveManagement.documents.chooseOption")), 1),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.categories, (category) => {
                          return (_openBlock(), _createElementBlock("option", {
                            key: category.pk,
                            value: category.pk
                          }, _toDisplayString(category.label), 9, _hoisted_21))
                        }), 128))
                      ], 512), [
                        [_vModelSelect, this.action.category]
                      ])
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_22, [
                _createElementVNode("label", _hoisted_23, _toDisplayString(_ctx.$t("pages.roadmap.collaborators")), 1),
                _createElementVNode("div", _hoisted_24, [
                  _createElementVNode("div", _hoisted_25, [
                    _createElementVNode("div", _hoisted_26, [
                      _withDirectives(_createElementVNode("textarea", {
                        class: "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                        placeholder: _ctx.$t('pages.roadmap.collaborators'),
                        name: "collaborators",
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((this.action.collaborators) = $event))
                      }, null, 8, _hoisted_27), [
                        [_vModelText, this.action.collaborators]
                      ])
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_28, [
                _createElementVNode("label", _hoisted_29, _toDisplayString(_ctx.$t("pages.roadmap.deadline")), 1),
                _createElementVNode("div", _hoisted_30, [
                  _createElementVNode("div", _hoisted_31, [
                    _createElementVNode("div", _hoisted_32, [
                      _withDirectives(_createElementVNode("input", {
                        type: "date",
                        class: "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                        placeholder: "Date d'écheance",
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((this.action.deadline) = $event))
                      }, null, 512), [
                        [_vModelText, this.action.deadline]
                      ])
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_33, [
                _createElementVNode("label", _hoisted_34, _toDisplayString(_ctx.$t("pages.roadmap.impact")), 1),
                _createElementVNode("div", _hoisted_35, [
                  _createElementVNode("div", _hoisted_36, [
                    _createElementVNode("div", _hoisted_37, [
                      _withDirectives(_createElementVNode("select", {
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((this.action.impact) = $event)),
                        class: "form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                      }, [
                        _createElementVNode("option", _hoisted_38, _toDisplayString(_ctx.$t("pages.driveManagement.documents.chooseOption")), 1),
                        _createElementVNode("option", _hoisted_39, _toDisplayString(_ctx.$t("pages.roadmap.lowImpact")), 1),
                        _cache[9] || (_cache[9] = _createElementVNode("option", { value: "2" }, "2", -1)),
                        _cache[10] || (_cache[10] = _createElementVNode("option", { value: "3" }, "3", -1)),
                        _cache[11] || (_cache[11] = _createElementVNode("option", { value: "4" }, "4", -1)),
                        _createElementVNode("option", _hoisted_40, _toDisplayString(_ctx.$t("pages.roadmap.highImpact")), 1)
                      ], 512), [
                        [_vModelSelect, this.action.impact]
                      ])
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_41, [
                _createElementVNode("label", _hoisted_42, _toDisplayString(_ctx.$t("pages.roadmap.status")), 1),
                _createElementVNode("div", _hoisted_43, [
                  _createElementVNode("div", _hoisted_44, [
                    _createElementVNode("div", _hoisted_45, [
                      _withDirectives(_createElementVNode("select", {
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((this.action.status) = $event)),
                        class: "form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                      }, [
                        _createElementVNode("option", _hoisted_46, _toDisplayString(_ctx.$t("pages.roadmap.todo")), 1),
                        _createElementVNode("option", _hoisted_47, _toDisplayString(_ctx.$t("pages.roadmap.inProgress")), 1),
                        _createElementVNode("option", _hoisted_48, _toDisplayString(_ctx.$t("pages.roadmap.done")), 1)
                      ], 512), [
                        [_vModelSelect, this.action.status]
                      ])
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_49, [
                _createElementVNode("label", _hoisted_50, _toDisplayString(_ctx.$t("pages.roadmap.commentaries")), 1),
                _createElementVNode("div", _hoisted_51, [
                  _createElementVNode("div", _hoisted_52, [
                    _createElementVNode("div", _hoisted_53, [
                      _withDirectives(_createElementVNode("textarea", {
                        class: "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                        placeholder: _ctx.$t('pages.roadmap.commentaries'),
                        name: "action",
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((this.action.commentary) = $event))
                      }, null, 8, _hoisted_54), [
                        [_vModelText, this.action.commentary]
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_55, [
          (!_ctx.editing)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                type: "submit",
                onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.addAction && _ctx.addAction(...args))),
                id: "kt_account_profile_details_submit",
                ref: "submitButton",
                class: "btn btn-primary"
              }, [
                _createElementVNode("span", _hoisted_56, _toDisplayString(_ctx.$t("general.add")), 1),
                _createElementVNode("span", _hoisted_57, [
                  _createTextVNode(_toDisplayString(_ctx.$t("general.pleaseWait")) + " ", 1),
                  _cache[12] || (_cache[12] = _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle ms-2" }, null, -1))
                ]),
                _createElementVNode("span", _hoisted_58, [
                  _createTextVNode(_toDisplayString(_ctx.$t("general.done")) + " ", 1),
                  _cache[13] || (_cache[13] = _createElementVNode("span", { class: "fas fa-check f-5 ms-3" }, null, -1))
                ])
              ], 512))
            : (_openBlock(), _createElementBlock("button", {
                key: 1,
                type: "submit",
                onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.updateAction && _ctx.updateAction(...args))),
                id: "kt_account_profile_details_submit",
                ref: "submitButton",
                class: "btn btn-primary"
              }, [
                _createElementVNode("span", _hoisted_59, _toDisplayString(_ctx.$t("general.update")), 1),
                _createElementVNode("span", _hoisted_60, [
                  _createTextVNode(_toDisplayString(_ctx.$t("general.pleaseWait")) + " ", 1),
                  _cache[14] || (_cache[14] = _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle ms-2" }, null, -1))
                ]),
                _createElementVNode("span", _hoisted_61, [
                  _createTextVNode(_toDisplayString(_ctx.$t("general.done")) + " ", 1),
                  _cache[15] || (_cache[15] = _createElementVNode("span", { class: "fas fa-check f-5 ms-3" }, null, -1))
                ])
              ], 512))
        ])
      ])
    ])
  ]))
}